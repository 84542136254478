<template>
  <div class="login">
    <main>
      <div class="title">欢迎登录</div>
      <div class="warn">未注册手机号将自动创建亿楼账号</div>
      <div class="tabs">
        <div
          class="tab-item"
          :class="tab === 1 ? 'active' : ''"
          @click="toTabs(1)"
        >
          验证码登录
        </div>
        <div
          class="tab-item"
          :class="tab === 2 ? 'active' : ''"
          @click="toTabs(2)"
        >
          密码登录
        </div>
      </div>
      <div class="main">
        <el-input
          type="number"
          placeholder="请输入手机号码"
          v-model="phone"
          style="margin-bottom: 32px"
          autofocus
          @input="isPhone"
        >
          <i slot="prefix" class="icon icon-phone"></i>
        </el-input>
        <el-input
          v-if="tab === 1"
          placeholder="请输入验证码"
          type="number"
          v-model="code"
          style="margin-bottom: 16px"
        >
          <i slot="prefix" class="icon icon-code"></i>
          <span slot="suffix" class="code" @click="setCode">{{
            count > 59 ? '获取验证码' : count
          }}</span>
        </el-input>
        <el-input
          v-else
          :type="type"
          placeholder="请输入密码"
          v-model="password"
          style="margin-bottom: 16px"
        >
          <i slot="prefix" class="icon icon-password"></i>
          <i
            slot="suffix"
            class="icon"
            :class="lookBool ? 'icon-look' : 'icon-unlook'"
            @click="setLook"
          ></i>
        </el-input>
        <div class="checkbox">
          <!--<el-checkbox v-model="checked">7天内免密登录</el-checkbox>-->
          <div></div>
          <div
            v-if="tab === 2"
            class="forget-the-password"
            @click="retrievePasswordVisible = true"
          >
            忘记密码？
          </div>
        </div>
      </div>
      <div class="dialog-footer">
        <el-button class="btn" type="primary" @click="login('web')"
          >登 录</el-button
        >
        <p>
          登录即代表同意<span @click="openWindow('/privacyPolicy')"
            >《亿楼隐私政策》</span
          >及<span @click="openWindow('/userAgreement')"
            >《亿楼用户服务协议》</span
          >
        </p>
      </div>
    </main>
    <!-- 设置密码 -->
    <el-dialog
      title="设置密码"
      :visible.sync="setPassWordVisible"
      :close-on-click-modal="false"
      width="480px"
      :before-close="setPassWordClose"
    >
      <div class="body">
        <el-input
          :type="type1 ? 'password' : 'text'"
          placeholder="请输入密码，至少6个字符"
          v-model="oldPassWord"
          style="margin-bottom: 20px; margin-top: 12px"
        >
          <i
            slot="suffix"
            class="icon"
            :class="!type1 ? 'icon-look' : 'icon-unlook'"
            @click="type1 = !type1"
          ></i>
        </el-input>

        <el-input
          :type="type2 ? 'password' : 'text'"
          placeholder="请再次输入密码"
          v-model="newPassWord"
          style="margin-bottom: 32px"
        >
          <i
            slot="suffix"
            class="icon"
            :class="!type2 ? 'icon-look' : 'icon-unlook'"
            @click="type2 = !type2"
          ></i>
        </el-input>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button class="btn-text" type="text" @click="next"
          >暂时跳过</el-button
        >
        <el-button class="confirm" type="primary" @click="confirmPassWord"
          >确定</el-button
        >
      </span>
    </el-dialog>
    <!-- 找回密码 -->
    <el-dialog
      title="找回密码"
      :visible.sync="retrievePasswordVisible"
      width="480px"
      :before-close="retrievePasswordClose"
    >
      <div class="body">
        <el-input
          placeholder="请输入手机号"
          v-model="retrieve.phone"
          style="margin-bottom: 20px; margin-top: 12px"
        >
        </el-input>
        <el-input
          placeholder="请输入验证码"
          v-model="retrieve.code"
          style="margin-bottom: 16px"
        >
          <span slot="suffix" class="code" @click="setPassCode">{{
            count > 59 ? '获取验证码' : count
          }}</span>
        </el-input>
        <el-input
          :type="type3 ? 'password' : 'text'"
          placeholder="请输入新密码，至少6个字符"
          v-model="retrieve.oldPassWord"
          style="margin-bottom: 20px"
        >
          <i
            slot="suffix"
            class="icon"
            :class="!type3 ? 'icon-look' : 'icon-unlook'"
            @click="type3 = !type3"
          ></i>
        </el-input>

        <el-input
          :type="type4 ? 'password' : 'text'"
          placeholder="请再次输入新密码"
          v-model="retrieve.newPassWord"
          style="margin-bottom: 32px"
        >
          <i
            slot="suffix"
            class="icon"
            :class="!type4 ? 'icon-look' : 'icon-unlook'"
            @click="type4 = !type4"
          ></i>
        </el-input>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button class="btn-text" @click="cancel">取消</el-button>
        <el-button class="confirm" type="primary" @click="retrieveConfirm"
          >确定</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { ticket, getCode, login, loginPassword, userInfo, initPassWord, passwordCode, resetPassword } from 'src/services/login';
export default {
  name: 'Login',
  data () {
    return {
      type: 'password',
      type1: true,
      type2: true,
      type3: true,
      type4: true,
      tab: 1,
      phone: null,
      oldPhone: '',
      code: '',
      password: '',
      lookBool: false,
      oldPassWord: '',
      newPassWord: '',
      count: 60,
      checked: false,
      setPassWordVisible: false,
      retrievePasswordVisible: false,
      retrieve: {
        phone: '',
        code: '',
        oldPassWord: '',
        newPassWord: '',
      },
      // 跳转过来的地址
      appReferer: '',
      from: null
    };
  },
  watch: {
    retrievePasswordVisible (val) {
      if (!val) {
        this.type3 = true
        this.type4 = true
        this.retrieve = {
          phone: '',
          code: '',
          oldPassWord: '',
          newPassWord: '',
        }
      }
    }
  },
  mounted () {
    console.log(this);
    // 判断是否是跳转过来的
    if (window.location.hash.indexOf('from=') !== -1) {
      const from = window.location.hash.split('from=')[1]
      if (from) {
        // 其他页面跳转
        this.appReferer = document.referrer || window.location.href
        this.from = from
      } else {
        // 本页面登录
        this.appReferer = window.location.href
        console.log(this.appReferer)
      }
    } else {
      this.appReferer = window.location.href
      console.log(this.appReferer)
    }
  },
  methods: {
    openWindow (path) {
      let routeUrl = this.$router.resolve({
        path
      })
      window.open(routeUrl.href, '_blank');
    },
    isPhone (val) {
      if (val.length !== 11) {
        this.phone = this.phone.slice(0, 11)
      }
    },
    toTabs (num) {
      this.tab = num
      if (this.timer)
        clearInterval(this.timer)
      this.timer = null
      this.count = 60
    },
    async setCode () {
      if (!this.timer) {
        var reg = /^(?:(?:\+|00)86)?1(?:(?:3[\d])|(?:4[5-79])|(?:5[0-35-9])|(?:6[5-7])|(?:7[0-8])|(?:8[\d])|(?:9[1589]))\d{8}$/;
        if (!this.phone) {
          this.$message.warning('请输入手机号')
        } else if (!reg.test(this.phone)) {
          this.$message.warning('请正确输入手机号')
        } else {
          let res = await getCode({ phone: this.phone });
          if (res.data?.status === 200) {
            this.$message.success(res.data.msg)
          }
          this.timer = setInterval(() => {
            this.count--
            if (this.count < 1) {
              clearInterval(this.timer)
              this.timer = null
              this.count = 60
            }
          }, 1000)
        }
      }
    },
    async setPassCode () {
      if (!this.timer) {
        var reg = /^(?:(?:\+|00)86)?1(?:(?:3[\d])|(?:4[5-79])|(?:5[0-35-9])|(?:6[5-7])|(?:7[0-8])|(?:8[\d])|(?:9[1589]))\d{8}$/;
        if (!this.retrieve.phone) {
          this.$message.warning('请输入手机号')
        } else if (!reg.test(this.retrieve.phone)) {
          this.$message.warning('请正确输入手机号')
        } else {
          let res = await passwordCode({ phone: this.retrieve.phone });
          if (res.data?.status === 200) {
            this.$message.success(res.data.msg)
          }
          this.timer = setInterval(() => {
            this.count--
            if (this.count < 1) {
              clearInterval(this.timer)
              this.timer = null
              this.count = 60
            }
          }, 1000)
        }
      }
    },
    setLook () {
      this.lookBool = !this.lookBool
      if (this.lookBool) {
        this.type = ''
      } else {
        this.type = 'password'
      }
    },
    login (client) {
      // 区分是密码登录还是验证码登录
      // var reg = /^(?:(?:\+|00)86)?1(?:(?:3[\d])|(?:4[5-79])|(?:5[0-35-9])|(?:6[5-7])|(?:7[0-8])|(?:8[\d])|(?:9[1589]))\d{8}$/;

      if (!this.phone) {
        this.$message.warning('请输入手机号')
        // } else if (!reg.test(this.phone)) {
        //   this.$message.warning('请正确输入手机号')
      } else {
        if (this.tab === 1) {
          // 验证码登录
          if (!this.code) {
            this.$message.warning('请输入验证码')
          } else {
            const data = {
              phone: this.phone,
              code: this.code,
              client
            }
            login(data).then(async res => {
              console.log(res);
              if (res.data.status === 200) {
                localStorage.token = res.data.data.token
                this.phone = null;
                this.code = null;
                const info = await userInfo()
                if (info.data.status === 200)
                  // 先判断是否需要设置密码
                  if (info.data.data.initPwd && !localStorage.nextPassWord) {
                    this.setPassWordVisible = true
                  } else {
                    this.open()
                  }
              }
            })
          }
        } else {
          // 密码登录
          if (!this.password) {
            this.$message.warning('请输入密码')
          } else {
            const data = {
              username: this.phone,
              password: this.$md5(this.password),
              client
            }
            loginPassword(data).then(async res => {
              console.log(res);
              if (res.data.status === 200) {
                localStorage.token = res.data.data.token
                this.phone = null;
                this.password = null;
                this.open()
              }
            })
          }
        }
      }
    },
    setPassWordClose () {
      this.setPassWordVisible = false
    },
    async next () {
      localStorage.nextPassWord = 'next'
      this.setPassWordVisible = false
      this.open()
    },
    // 跳转页面
    async open () {
      // 获取子应用Ticket
      let ticketVal = await ticket({ client: 'web', appReferer: this.appReferer })
      if (this.appReferer) {
        console.log(this.appReferer);
        // 如果this.appReferer === 当前页面地址就跳转用户端主页
        if (this.appReferer !== window.location.href) {
          let hash = this.appReferer.split('/#/')
          window.location.href = hash[0] + "#/login?from=" + this.from + '&ticket=' + ticketVal.data.data.ticket;
        } else {
          // 跳转用户端pc页面
          window.location.href = window.userPC + "#/login?from=#/plant" + '&ticket=' + ticketVal.data.data.ticket;
        }
      }
    },
    // 设置密码
    confirmPassWord () {
      if (!this.oldPassWord) {
        this.$message.warning('请输入密码')
      } else if (this.oldPassWord !== this.newPassWord) {
        this.$message.warning('请确认两次输入的密码是否相同！')
      } else {
        const data = {
          password: this.$md5(this.newPassWord),
        }
        initPassWord(data).then(async res => {
          console.log(res);
          if (res.data.status === 200) {
            this.setPassWordVisible = false
            this.oldPassWord = null;
            this.newPassWord = null;
            this.open()
          }
        })
      }
    },
    retrievePasswordClose () {
      this.retrievePasswordVisible = false
    },
    cancel () {
      this.retrievePasswordVisible = false
    },
    retrieveConfirm () {
      var reg = /^(?:(?:\+|00)86)?1(?:(?:3[\d])|(?:4[5-79])|(?:5[0-35-9])|(?:6[5-7])|(?:7[0-8])|(?:8[\d])|(?:9[1589]))\d{8}$/;
      if (!this.retrieve.phone) {
        this.$message.warning('请输入手机号')
      } else if (!reg.test(this.retrieve.phone)) {
        this.$message.warning('请正确输入手机号')
      } else if (!this.retrieve.code) {
        this.$message.warning('请输入验证码')
      } else if (!this.retrieve.oldPassWord) {
        this.$message.warning('请输入密码')
      } else if (this.retrieve.oldPassWord !== this.retrieve.newPassWord) {
        this.$message.warning('请确认两次输入的密码是否相同！')
      } else {
        const data = {
          phone: this.retrieve.phone,
          code: this.retrieve.code,
          password: this.$md5(this.retrieve.newPassWord),
        }
        resetPassword(data).then(res => {
          if (res.data.status === 200) {
            this.$message({
              message: res.data.msg,
              type: 'success'
            });
            this.retrievePasswordVisible = false
            this.retrieve = {
              phone: '',
              code: '',
              oldPassWord: '',
              newPassWord: '',
            }
          }
        })
      }
    },
  }
};
</script>
<style lang="less" scoped>
.login {
  width: 100%;
  height: 100%;
  background: url(../assets/login/bg.png);
  background-size: 1080px 100%;
  background-repeat: no-repeat;
  display: flex;
  justify-content: end;
  main {
    padding-top: 220px;
    padding-right: 160px;
    .title {
      font-size: 40px;
      font-weight: 400;
      line-height: 58px;
      color: rgba(51, 51, 51, 1);
    }
    .warn {
      margin-bottom: 24px;
      font-size: 16px;
      font-weight: 400;
      line-height: 24px;
      color: rgba(153, 153, 153, 1);
    }
    .tabs {
      display: flex;
      align-items: center;
      margin-bottom: 32px;
      .tab-item {
        font-size: 24px;
        font-weight: 400;
        line-height: 36px;
        cursor: pointer;
        color: rgba(51, 51, 51, 1);
        margin-right: 73px;
        &.active {
          color: rgba(236, 102, 0, 1);
          &::after {
            content: '';
            display: block;
            width: 36px;
            height: 3px;
            opacity: 1;
            border-radius: 8px;
            background: rgba(236, 102, 0, 1);
            margin: 0 auto;
          }
        }
      }
    }
  }
  .main {
    width: 460px;
    font-size: 18px;
    line-height: 24px;
    color: rgba(51, 51, 51, 1);
    .code {
      font-size: 18px;
      font-weight: 400;
      line-height: 64px;
      color: rgba(236, 102, 0, 1);
      display: flex;
      align-items: center;
      cursor: pointer;
    }
    .checkbox {
      display: flex;
      justify-content: space-between;
      margin-bottom: 38px;
      .forget-the-password {
        font-size: 18px;
        font-weight: 400;
        color: rgba(51, 51, 51, 1);
        cursor: pointer;
        padding-right: 30px;
      }
    }

    /deep/ .el-checkbox {
      display: flex;
      align-items: center;
    }
    /deep/ .el-checkbox__label {
      font-size: 18px;
      font-weight: 400;
      color: rgba(51, 51, 51, 1);
    }
    /deep/.el-checkbox__input.is-checked + .el-checkbox__label {
      color: rgba(51, 51, 51, 1);
    }
    /deep/ .el-checkbox__inner:hover {
      border: 1px solid rgba(236, 102, 0, 1);
    }
    /deep/ .el-checkbox__input.is-checked .el-checkbox__inner {
      border: 1px solid rgba(236, 102, 0, 1);
      background: rgba(236, 102, 0, 1);
      &::after {
        // border-bottom: 1px solid rgba(236, 102, 0, 1);
        // border-right: 1px solid rgba(236, 102, 0, 1);
      }
    }
    /deep/ .el-input__inner {
      width: 460px;
      height: 64px;
      line-height: 64px;
      padding-left: 62px !important;
      opacity: 1;
      border-radius: 60px;
      background: rgba(255, 249, 239, 1);
      color: #333;
      border: 0;
      font-size: 20px;
      &::placeholder {
        font-size: 20px;
        color: #999;
      }
    }
    /deep/ .el-input__prefix {
      display: flex;
      align-items: center;
      .el-input__suffix-inner {
        display: flex;
        align-items: center;
      }
      left: 30px;
    }
    /deep/ .el-input__suffix {
      display: flex;
      align-items: center;
      .el-input__suffix-inner {
        display: flex;
        align-items: center;
      }
      right: 30px;
    }
  }

  .dialog-footer {
    text-align: center;
    .btn {
      width: 460px;
      height: 64px !important;
      border-radius: 60px;
      background: linear-gradient(
        0deg,
        rgba(245, 112, 10, 1) 0%,
        rgba(255, 217, 179, 1) 100%
      );
      border: 0;
      font-size: 24px;
      font-weight: 400;
      color: rgba(255, 255, 255, 1);
      margin-bottom: 16px;
    }
    p {
      font-size: 16px;
      font-weight: 400;
      line-height: 24px;
      color: rgba(51, 51, 51, 1);
      span {
        color: rgba(236, 102, 0, 1);
        cursor: pointer;
      }
    }
  }
}

// 弹窗

/deep/ .el-dialog__header {
  text-align: center;
  padding: 20px 50px 12px;
  .el-dialog__title {
    font-size: 20px;
    font-weight: 500;
    letter-spacing: 0px;
    line-height: 24px;
    color: rgba(51, 51, 51, 1);
  }
  .el-dialog__headerbtn {
    font-size: 16px;
  }
}
/deep/ .el-dialog__body {
  padding: 0px 40px;
}

.body {
  .code {
    font-size: 16px;
    font-weight: 400;
    line-height: 50px;
    color: rgba(236, 102, 0, 1);
    display: flex;
    align-items: center;
    cursor: pointer;
  }
  /deep/ .el-input__inner {
    width: 400px;
    height: 50px;
    line-height: 50px;
    padding-left: 25px !important;
    opacity: 1;
    border-radius: 4px;
    background: rgba(247, 247, 247, 1);
    border: 0;
    font-size: 16px;
    &::placeholder {
      font-size: 16px;
    }
  }
  /deep/ .el-input__suffix {
    display: flex;
    align-items: center;
    right: 25px;
    .el-input__suffix-inner {
      display: flex;
      align-items: center;
    }
  }
  /deep/ .el-input .el-input__clear {
    font-size: 20px;
  }
}
/deep/ .el-dialog__footer {
  text-align: center;
  .btn-text {
    width: 88px;
    height: 40px;
    font-size: 16px;
    font-weight: 400;
    color: rgba(153, 153, 153, 1);
  }
  .dialog-footer {
    .confirm {
      width: 88px;
      height: 40px;
      opacity: 1;
      border-radius: 4px;
      background: rgba(236, 102, 0, 1);
      border: 0;
      margin-left: 20px;
      font-size: 16px;
    }
  }
}
</style>
<style lang="less" scoped>
.icon {
  display: inline-block;
  background-size: 100% 100%;
  &-phone {
    width: 24px;
    height: 24px;
    background-image: url(../assets/login/icon-phone.png);
  }
  &-password {
    width: 24px;
    height: 24px;
    background-image: url(../assets/login/icon-password.png);
  }
  &-look {
    width: 24px;
    height: 24px;
    background-image: url(../assets/login/icon-look.png);
  }
  &-unlook {
    width: 24px;
    height: 24px;
    background-image: url(../assets/login/icon-unlook.png);
  }
  &-code {
    width: 24px;
    height: 24px;
    background-image: url(../assets/login/icon-code.png);
  }
}

/deep/ .el-input__inner::-webkit-outer-spin-button {
  -webkit-appearance: none;
  display: none;
}
/deep/ .el-input__inner::-webkit-inner-spin-button {
  -webkit-appearance: none;
  display: none;
}
input[type='number'] {
  -moz-appearance: textfield;
}
</style>
