import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import './plugins/axios';
import './plugins/element.js';
//导入md5.js
import md5 from 'js-md5';
import EventBus from './plugins/EventBus';
import './styles/reset.less';
import './styles/element.less';
import './styles/icon.less';

Vue.config.productionTip = false;
// 这个方式可以在任何组件里直接用 this.$EventBus 调用
Vue.prototype.$EventBus = EventBus;
//将md5转换成vue原型：
Vue.prototype.$md5 = md5;

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app');
