import Vue from 'vue';
import Vuex from 'vuex';

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    loginState: true
  },
  mutations: {
    setLoginState(state, val) {
      state.loginState = val;
    }
  },
  actions: {},
  modules: {}
});
