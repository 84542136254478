<template>
  <router-view id="app" />
</template>

<script>
// import { scaleListener } from '../src/plugins/resize'
export default {
  name: 'app',
  mounted () { },
  methods: {}
};
</script>

<style>
#app {
  height: 100%;
}
</style>
