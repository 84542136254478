import axios from 'plugins/axios';
import qs from 'qs';

// form 表单传参
const form = {
  transformRequest: [
    function (data) {
      data = qs.stringify(data);
      return data;
    }
  ],
  headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
};

// 查询是否登录
const isLogin = () => axios.get('/auth/isLogin');

// 获取临时token
const ticket = async data =>
  axios.post('/sub/auth/ticket/generate', data, form);
// 获取token
const token = async data => axios.post('/sub/auth/login/subApp', data, form);

// 验证码登录
const login = data => axios.post('/auth/login/sms', data, form);

// 密码登录
const loginPassword = data => axios.post('/auth/login/password', data, form);

// 发送短信验证码----post表单提交
const getCode = data => axios.post('/auth/login/sms/sendCode', data, form);

// 获取用户信息
const userInfo = () => axios.get('/user/get');

// 设置初始密码
const initPassWord = data => axios.post('/user/password/set/init', data, form);

// 忘记密码-发送验证码----post表单提交
const passwordCode = data =>
  axios.post('/user/password/forget/code/send', data, form);

// 忘记密码-设置密码----post表单提交
const resetPassword = data =>
  axios.post('/user/password/forget/reset', data, form);

export {
  isLogin,
  ticket,
  token,
  login,
  loginPassword,
  getCode,
  userInfo,
  initPassWord,
  passwordCode,
  resetPassword
};
