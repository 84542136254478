import Vue from 'vue';
import VueRouter from 'vue-router';
import Login from '../views/Login.vue';

// 开始添加代码
const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err);
};

Vue.use(VueRouter);

const routes = [
  {
    path: '/login',
    component: Login
  },
  {
    path: '/ticket',
    component: () => import(/* webpackChunkName: "home" */ 'views/Ticket.vue')
  },
  // 用户使用协议
  {
    path: '/userAgreement',
    name: 'userAgreement',
    component: () =>
      import(/* webpackChunkName: "home" */ 'views/UserAgreement.vue')
  },
  // 亿楼隐私政策
  {
    path: '/privacyPolicy',
    name: 'privacyPolicy',
    component: () =>
      import(/* webpackChunkName: "home" */ 'views/PrivacyPolicy.vue')
  }
];

const router = new VueRouter({
  routes
});

export default router;
